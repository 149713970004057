import { Route, Routes } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Navbar from '../Navbar';
import Investments from '../Investments';
import Investment from '../Investments/Investment';
import jwt from 'jwt-decode';

const RequireAuth = () => {
    const { auth } = useAuth();

    let useToken = localStorage.getItem("token")
    let tokenExpJwt = useToken && jwt(useToken).exp
    let currentDate = new Date()
    let epochTime = currentDate.getTime() / 1000

    return (
        auth?.accessToken || epochTime < tokenExpJwt
            ?
            <Navbar />
            :
            <Routes>
                <Route path="/" element={<Navbar />} >
                    <Route path='/investments' element={<Investments />} />
                    <Route path='/investment/:id' element={<Investment />} />
                </Route>
            </Routes>
    )
}

export default RequireAuth;