import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import SliderShow from "../../SliderShow";
import axios from "axios";
import { Box, Container, Typography, Grid } from "@mui/material";
import InvestmentInProgress from "./InvestmentInProgress";
import { TfiEmail, TfiMobile, TfiUser } from "react-icons/tfi";
import VerticalTimeline from "./VerticalTimeline";
import Description from "./Description";

const formatPrice = (price) => {
    price = price / 100
    return parseFloat(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
}

const dateDiffsDays = (date) => {
    // Pobranie aktualnej daty i wyzerowanie godziny
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Tworzenie obiektu daty dla docelowej daty
    const endDate = new Date(date);
    endDate.setHours(0, 0, 0, 0); // Zerujemy godziny, aby uniknąć przesunięć

    // Różnica w dniach
    const diffDate = endDate.getTime() - currentDate.getTime();

    return Math.floor(diffDate / (1000 * 60 * 60 * 24) + 1);
};

const Investment = () => {
    const printRef = useRef()

    const { id } = useParams()
    const [images, setImages] = useState([])
    const [title, setTitle] = useState("")
    const [supervisorEmail, setSupervisorEmail] = useState("")
    const [supervisorName, setSupervisorName] = useState("")
    const [supervisorPhone, setSupervisorPhone] = useState("")
    const [dividendPaymentDates, setDividendPaymentDates] = useState([])
    const [investedAmount, setInvestedAmount] = useState(0.00)
    const [requiredAmount, setRequiredAmount] = useState(0.00)
    const [investedPercentage, setInvestedPercentage] = useState(0.00)
    const [investorsNo, setInvestorsNo] = useState(0)
    const [loanStartDate, setLoanStartDate] = useState()
    const [loanPaidDate, setLoanPaidDate] = useState()
    const [annualYield, setAnnualYield] = useState(27.00)
    const [totalYield, setTotalYield] = useState(35.50)
    const [ratingLTV, setRaingLTV] = useState(25.67)
    const [scoringRate, setScoringRate] = useState("")
    const [minInvest, setMinInvest] = useState(0)
    const requiredAmountFormatted = formatPrice(requiredAmount)
    const investedAmountFormatted = formatPrice(investedAmount)
    const daysToFinished = dateDiffsDays(loanPaidDate)

    const getProject = async () => {
        axios.get(`/projects/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            res.data.pictures !== null && res.data.pictures.map(el => {
                return setImages(prevState => [...prevState, el.picture.original])
            });
            setInvestorsNo(res.data.investors_no)
            setTitle(res.data.name)
            setRequiredAmount(res.data.required_amount)
            setInvestedAmount(res.data.invested_amount)
            setLoanPaidDate(res.data.end_time)
            setRaingLTV(res.data.rating_ltv)
            setInvestedPercentage(res.data.invested_percentage)
            setDividendPaymentDates(res.data.dividend_payment_dates)
            setSupervisorEmail(res.data.collection_supervisor_email)
            setSupervisorName(res.data.collection_supervisor_name)
            setSupervisorPhone(res.data.collection_supervisor_phone_number)
            setScoringRate(res.data.scoring_rate)
            setAnnualYield(res.data.annual_yield)
            setTotalYield(res.data.total_yield)
            setLoanStartDate(res.data.loan_start_date)
            setLoanPaidDate(res.data.loan_paid_date)
            setMinInvest(res.data.minimal_investment_amount)
        }).catch(err => {
            console.error(err)
        })
    }
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        getProject()
    }, [])

    return (
        <Container maxWidth="lg" sx={{ marginTop: "150px" }}>
            <div ref={printRef}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Typography
                        component="h1"
                        variant="h4"
                        color={"rgba(0, 0, 0, 0.5)"}
                        fontSize={24}
                        sx={{ marginBottom: "24px" }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={8} >
                        <SliderShow images={images} />
                        <Description
                            annualYield={annualYield}
                            totalYield={totalYield}
                            requiredAmountFormatted={requiredAmountFormatted}
                            requiredAmount={requiredAmount}
                            dividendPaymentDates={dividendPaymentDates}
                            id={id}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <InvestmentInProgress
                            investorsNo={investorsNo}
                            annualYield={annualYield}
                            totalYield={totalYield}
                            ratingLTV={ratingLTV}
                            investedPercentage={investedPercentage}
                            requiredAmountFormatted={requiredAmountFormatted}
                            investedAmountFormatted={investedAmountFormatted}
                            daysToFinished={daysToFinished}
                            scoringRate={scoringRate}
                            minInvest={minInvest}
                            handlePrint={handlePrint}
                            style={{ marginBottom: "30px" }}
                        />
                        <Box sx={{ background: "#fff", padding: "20px", margin: "30px 0" }}>
                            <Typography fontSize={21} color="rgba(0, 0, 0, 0.5)" textAlign={"center"} marginBottom={2}>
                                Opiekun zbiórki
                            </Typography>
                            <Typography color="rgba(0, 0, 0, 0.5)">
                                <TfiUser style={{ marginRight: "10px" }} />
                                {supervisorName ? supervisorName : "Opiekun zbiórki"}
                            </Typography>
                            <Typography color="rgba(0, 0, 0, 0.5)">
                                <TfiMobile style={{ marginRight: "10px" }} />
                                {supervisorPhone ? supervisorPhone : "+48123456789"}
                            </Typography>
                            <Typography color="rgba(0, 0, 0, 0.5)">
                                <TfiEmail style={{ marginRight: "10px" }} />
                                {supervisorEmail ? supervisorEmail : "a@gmail.com"}
                            </Typography>
                        </Box>
                        <VerticalTimeline loanStartDate={loanStartDate} loanPaidDate={loanPaidDate} />
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default Investment;